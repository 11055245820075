import React from "react";
import { motion } from "framer-motion";
function Hobby() {
  return (
    <motion.div
      initial={{ opacity: 0, nsition: { duration: 1 } }}
      animate={{ opacity: 1, nsition: { duration: 1 } }}
      exit={{ opacity: 0, nsition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      className="text-white"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-8">
            <div
              className="addFont heading-color"
              style={{ fontWeight: "300", fontSize: "30px" }}
            >
              <kbd className="addFont">Hobbies</kbd>
            </div>
            <br />
            <ul
              className="addFont fontWeight200 fontSizeHobby"
              style={{ marginTop: "-50px", marginBottom: "150px" }}
            >
              <li className="heading-color mt-5">
                Photography
                <a
                  href="https://unsplash.com/@deepakmp444"
                  style={{ fontSize: "20px", textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <kbd>Link</kbd>
                </a>
              </li>
              <li className="heading-color">Videography</li>
              <li className="heading-color me-2">
                Reading about new technology
              </li>
            </ul>
          </div>
          <div className="col-sm-2"></div>
        </div>
      </div>
    </motion.div>
  );
}

export default Hobby;
