function Resume() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <div
        className="d-flex justify-content-end"
        onClick={() =>
          openInNewTab(
            "https://drive.google.com/file/d/1Kz5iet_L1vwuc7T9Osz0TUvIpg-ZK-O1/view?usp=sharing"
          )
        }
      >
        <div className="btnc" style={{ cursor: "pointer" }}>
          <p
            className="btnwhite center addFont prevent-select"
            style={{ marginTop: "5px", cursor: "pointer" }}
          >
            Resume
          </p>
        </div>
      </div>
    </>
  );
}

export default Resume;
