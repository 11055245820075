import React from "react";
import { motion } from "framer-motion";
import skillImg from "../assets/Skills.png";
function Skill() {
  return (
    <motion.div
      initial={{ opacity: 0, nsition: { duration: 1 } }}
      animate={{ opacity: 1, nsition: { duration: 1 } }}
      exit={{ opacity: 0, nsition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      className="text-white container"
    >
      <div className="row skillMarginTop">
        <div className="conatiner">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <div
                className="addFont heading-color"
                style={{ fontWeight: "500", fontSize: "30px" }}
              >
                <kbd className="addFont">Skills</kbd>
              </div>
              <br />
              <img
                className="mx-auto d-block skillIconSize mt-3"
                src={skillImg}
                alt="loading"
                style={{ marginTop: "-10px" }}
              />
            </div>
            <div className="col-sm-2"></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Skill;
