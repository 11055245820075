import url from "../assets/urlshortnerv3.png";
import monster from "../assets/monster.png";
import salon from "../assets/salon.png";
import quiz from "../assets/Quiz.png";
import ask from "../assets/ask.png";
import gullar from "../assets/gullar.png";
import resume from "../assets/ResumeTemplate.png";
import { motion } from "framer-motion";

function Project() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <motion.div
      initial={{ opacity: 0, nsition: { duration: 1 } }}
      animate={{ opacity: 1, nsition: { duration: 1 } }}
      exit={{ opacity: 0, nsition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      className="text-white"
    >
      <div className="container">
        <div
          className="row d-flex justify-content-center mb-5"
          style={{ marginTop: "-70px" }}
        >
          <div className="col-sm-2"></div>
          <div className="col-sm-8">
            <div
              className="addFont heading-color mb-3"
              style={{ fontWeight: "500", fontSize: "30px" }}
            >
              <kbd className="addFont">Projects</kbd>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-3">
                <img
                  src={gullar}
                  className="img-fluid mouse-pointer"
                  alt="loading"
                  style={{ borderRadius: "15px" }}
                  onClick={() => openInNewTab("https://gullar.in")}
                />
                <p className="addFont mt-2">
                  <strong>Gullar</strong>
                </p>

                <div className="d-flex" style={{ marginTop: "-15px" }}>
                  <p
                    className="addFont mouse-pointer"
                    onClick={() =>
                      openInNewTab("https://github.com/deepakmp444/gullar")
                    }
                  >
                    Github
                  </p>

                  <p
                    className="addFont mouse-pointer ms-2"
                    onClick={() => openInNewTab("https://gullar.in")}
                  >
                    Visit
                  </p>
                </div>
              </div>

              {/* TODO:  Ask me anything */}
              <div className="col-sm-3">
                <img
                  src={ask}
                  className="img-fluid mouse-pointer"
                  alt="loading"
                  style={{ borderRadius: "15px" }}
                  onClick={() => openInNewTab("https://ai.deepakkumar.work/")}
                />
                <p className="addFont mt-2">
                  <strong>Ask me anything</strong>
                </p>
                <div className="d-flex" style={{ marginTop: "-15px" }}>
                  <p
                    className="addFont mouse-pointer"
                    onClick={() =>
                      openInNewTab(
                        "https://github.com/deepakmp444/react-openai-GPT-3"
                      )
                    }
                  >
                    Github
                  </p>

                  <p
                    className="addFont mouse-pointer ms-2"
                    onClick={() => openInNewTab("https://ai.deepakkumar.work/")}
                  >
                    Visit
                  </p>
                </div>
              </div>

              {/* TODO:  Quiz App */}
              <div className="col-sm-3">
                <img
                  src={quiz}
                  className="img-fluid mouse-pointer"
                  alt="loading"
                  style={{ borderRadius: "15px" }}
                  onClick={() =>
                    openInNewTab("https://quizapp.deepakkumar.work/")
                  }
                />
                <p className="addFont mt-2">
                  <strong>Quiz App</strong>
                </p>
                <div className="d-flex" style={{ marginTop: "-15px" }}>
                  <p
                    className="addFont mouse-pointer"
                    onClick={() =>
                      openInNewTab("https://github.com/deepakmp444/quiz-app")
                    }
                  >
                    Github
                  </p>

                  <p
                    className="addFont mouse-pointer ms-2"
                    onClick={() =>
                      openInNewTab("https://quizapp.deepakkumar.work/")
                    }
                  >
                    Visit
                  </p>
                </div>
              </div>
              {/* TODO:  Url shortner */}
              <div className="col-sm-3">
                <img
                  src={url}
                  className="img-fluid mouse-pointer"
                  alt="loading"
                  style={{ borderRadius: "15px" }}
                  onClick={() =>
                    openInNewTab("https://urlshortner.deepakkumar.work/")
                  }
                />
                <p className="addFont mt-2">
                  <strong>URL shortener</strong>
                </p>
                <div className="d-flex" style={{ marginTop: "-15px" }}>
                  <p
                    className="addFont mouse-pointer"
                    onClick={() =>
                      openInNewTab("https://github.com/deepakmp444/urlshortner")
                    }
                  >
                    Github
                  </p>

                  <p
                    className="addFont mouse-pointer ms-2"
                    onClick={() =>
                      openInNewTab("https://urlshortner.deepakkumar.work/")
                    }
                  >
                    Visit
                  </p>
                </div>
              </div>
              {/* TODO: Resume */}
              <div className="col-sm-3">
                <img
                  src={resume}
                  className="img-fluid mx-auto d-block mouse-pointer"
                  alt="loading"
                  style={{ borderRadius: "15px" }}
                  onClick={() =>
                    openInNewTab(
                      "https://www.figma.com/community/file/1184498469905883201"
                    )
                  }
                />
                <p className="addFont mt-2">
                  <strong>Resume: Figma</strong>
                </p>
                <div className="d-flex" style={{ marginTop: "-15px" }}>
                  <p
                    className="addFont mouse-pointer"
                    onClick={() =>
                      openInNewTab(
                        "https://www.figma.com/community/file/1184498469905883201"
                      )
                    }
                  >
                    200+ downloaded
                  </p>
                </div>
              </div>

              {/* TODO: Monster Filter */}
              <div className="col-sm-3">
                <img
                  src={monster}
                  className="img-fluid mouse-pointer"
                  alt="loading"
                  style={{ borderRadius: "15px" }}
                  onClick={() =>
                    openInNewTab(
                      "https://deepakmp444.github.io/ReactJs-fetchApi-filter/"
                    )
                  }
                />
                <p className="addFont mt-2">
                  <strong>Monsters Rolodex</strong>
                </p>
                <div className="d-flex" style={{ marginTop: "-15px" }}>
                  <p
                    className="addFont mouse-pointer"
                    onClick={() =>
                      openInNewTab(
                        "https://github.com/deepakmp444/ReactJs-fetchApi-filter"
                      )
                    }
                  >
                    Github
                  </p>

                  <p
                    className="addFont mouse-pointer ms-2"
                    onClick={() =>
                      openInNewTab(
                        "https://deepakmp444.github.io/ReactJs-fetchApi-filter/"
                      )
                    }
                  >
                    Visit
                  </p>
                </div>
              </div>
              {/* TODO: Digital Salon */}
              <div className="col-sm-3">
                <img
                  src={salon}
                  className="img-fluid mouse-pointer"
                  alt="loading"
                  style={{ borderRadius: "15px" }}
                  onClick={() =>
                    openInNewTab("https://github.com/deepakmp444/salon-Project")
                  }
                />
                <p className="addFont mt-2">
                  <strong>My Salon</strong>
                </p>
                <div className="d-flex" style={{ marginTop: "-15px" }}>
                  <p
                    className="addFont mouse-pointer"
                    onClick={() =>
                      openInNewTab(
                        "https://github.com/deepakmp444/salon-Project"
                      )
                    }
                  >
                    Github
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2"></div>
        </div>
      </div>
    </motion.div>
  );
}

export default Project;
