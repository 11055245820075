import Home from "./page/Home";
import Resume from "./components/Resume";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "./page/PageNotFound";

function App() {
  return (
    <>
      <div className="bg">
      
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>
      </div>
    </>
  );
}

export default App;
