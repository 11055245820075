import { motion } from "framer-motion";
// import Deepak from "../assets/deepak.jpeg";
import Deepak from "../assets/DeepakWork.png";
import Linkedin from "../assets/linkedin.svg";
import Github from "../assets/github.svg";
import Twitter from "../assets/twitter.svg";
import About from "./About";
import Skill from "./Skill";
import Experience from "./Experience";
import Project from "./Project";
import Hobby from "./Hobby";
import React, { useState, useEffect } from "react";
function Home() {
  const [isSticky, setIsSticky] = useState(false);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 360) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, nsition: { duration: 1 } }}
        animate={{ opacity: 1, nsition: { duration: 1 } }}
        exit={{ opacity: 0, nsition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <img
          src={Deepak}
          className="rounded-circle mx-auto d-block imgD"
          height="250px"
          alt=""
          style={{ marginTop: "70px" }}
        />
        <div className={`title ${isSticky ? "sticky" : ""}`}>
          <div
            className="text-center addFont mt-3 heading-color"
            style={{ fontWeight: "600", fontSize: "30px" }}
          >
            Deepak Kumar
          </div>
          <div
            className="text-center addFont sub-heading-color"
            style={{ fontWeight: "100", fontSize: "20px", marginTop: "-6px" }}
          >
            Full Stack Developer
          </div>
        </div>

        <div className="box mt-3">
          <div className="d-flex justify-content-evenly">
            <div
              className="mouse-pointer"
              onClick={() =>
                openInNewTab("https://www.linkedin.com/in/deepakmp444")
              }
            >
              <img src={Linkedin} alt="LinkedIn" />
            </div>
            <div
              className="mouse-pointer"
              onClick={() => openInNewTab("https://github.com/deepakmp444")}
            >
              <img src={Github} alt="Github" />
            </div>
            <div
              className="mouse-pointer"
              onClick={() => openInNewTab("https://twitter.com/deepakmp444")}
            >
              <img src={Twitter} alt="Twitter" />
            </div>
          </div>
        </div>
        <About />
        <Skill />
        <Experience />
        <Project />
        <Hobby />
      </motion.div>
    </>
  );
}

export default Home;
