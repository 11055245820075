import React from "react";

function PageNotFound() {
  return (
    <div
      className="text-center text-white addFont"
      style={{ marginTop: "250px" }}
    >
     <h1 className="addFont"> Page Not Found 😎</h1>
    </div>
  );
}

export default PageNotFound;
