import { motion } from "framer-motion";
function About() {
  return (
    <motion.div
      initial={{ opacity: 0, nsition: { duration: 1 } }}
      animate={{ opacity: 1, nsition: { duration: 1 } }}
      exit={{ opacity: 0, nsition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      className="addFont fontWeight400 heading-color"
      style={{ fontSize: "20px", marginTop: "30px" }}
    >
      <div className="container mb-5">
        <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-8">
            <div style={{ fontWeight: "500", fontSize: "30px" }}>
              <kbd className="addFont">About</kbd>
            </div>
            <br />
            <div style={{ fontWeight: "200", fontSize: "20px" }}>
              {/* As a former <strong>AR VR architect</strong> and current{" "}
              <strong>full stack developer</strong> , I have a unique blend of
              skills and experience in both of these fields. My background in AR
              VR architecture has given me a strong understanding of the
              technical aspects of creating immersive experiences, as well as an
              ability to think creatively and problem-solve.
              <br />
              <br /> */}
              In my current role as a full stack developer, I have honed my
              skills in a variety of programming languages and frameworks,
              including{" "}
              <strong>
                JavaScript, TypeScript, React, Express Js, MongoDB and
                PostgreSQL
              </strong>{" "}
              . I have worked on a range of projects, from developing web
              applications to building APIs and integrating them with front-end
              interfaces.
              <br />
              <br />I am constantly seeking to expand my knowledge and stay
              up-to-date with the latest technologies and industry trends. In
              addition to my work experience, I hold a Bachelor's degree in{" "}
              <strong>Computer Science</strong>.
              <br />
              <br />
              With my diverse skill set and passion for technology, I am
              confident in my ability to deliver high-quality solutions and
              drive success for any project I am involved in.
            </div>
          </div>
          <div className="col-sm-2"></div>
        </div>
      </div>
    </motion.div>
  );
}

export default About;
