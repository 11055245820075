import React from "react";
import { motion } from "framer-motion";
function Experience() {
  return (
    <motion.div
      initial={{ opacity: 0, nsition: { duration: 1 } }}
      animate={{ opacity: 1, nsition: { duration: 1 } }}
      exit={{ opacity: 0, nsition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      className="text-white"
    >
      <div
        className="container"
        style={{ marginBottom: "100px", marginTop: "50px" }}
      >
        <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-8">
            <div
              className="addFont heading-color"
              style={{ fontWeight: "500", fontSize: "30px" }}
            >
              <kbd className="addFont">Experience 2.5 Years</kbd>
            </div>
            <br />

            <div className="ms-3">
              <h1 className="addFont fontWeight900 heading-color">
                Micro Technologies
              </h1>

              <div className="addFont fontWeight500 heading-color">
                <span>Full Time</span>
              </div>
              <div className="addFont fontWeight500 heading-color">
                <span className="fontWeight200">
                  Bhopal, Madhya Pradesh, India
                </span>{" "}
              </div>
              <section className="addFont mt-3">
                <div style={{ marginTop: "15px" }}>
                  <h5 className="heading-color">Full Stack Developer</h5>
                  <p className="sub-heading-color fontWeight200">
                    Sept. 2022 - April 2023
                  </p>
                </div>
                <div>
                  <p className="sub-heading-color fontWeight200">
                    Designed and developed a user-friendly and responsive
                    website, as well as APIs using Node.js and Express. <br />{" "}
                    Utilised databases such as MySQL and MongoDB for storing and
                    retrieving data.
                    <br /> Fixed bugs from existing websites and implemented
                    enhancement that significantly improved web functionality
                    and speed.
                  </p>
                </div>
              </section>
              <div
                className="pointCSS2"
                style={{ marginTop: "-70px", marginLeft: "-7px" }}
              ></div>
            </div>
            {/* second  */}
            <div className="ms-3" style={{ marginTop: "100px" }}>
              <h1 className="addFont fontWeight900 heading-color">
                Paktolus Solution
              </h1>

              <div className="addFont fontWeight500 heading-color">
                <span>Full Time</span> | <span>1 Yr 10 Months</span>
              </div>
              <div className="addFont fontWeight500 heading-color">
                <span className="fontWeight200">
                  Mumbai, Maharashtra, India
                </span>{" "}
                <span>
                  {" "}
                  <kbd>Remote</kbd>{" "}
                </span>
              </div>
              <section className="addFont mt-3">
                <div style={{ marginTop: "10px" }}>
                  <h5 className="heading-color">Software Engineer</h5>
                  <p className="sub-heading-color fontWeight200">
                    Oct 2020 - Aug 2022
                  </p>
                </div>
              </section>
              <div style={{ marginTop: "10px" }}>
                <p className="sub-heading-color addFont fontWeight200">
                  Designing and building UI using Figma, React Js, and Next Js. As well as APIs using Express.
                  <br />
                  Collaborating with cross-functional teams, including
                  designers, developers, and project managers, to ensure the
                  quality and success of projects.
                  <br />
                  Solving complex technical challenges and debugging issues to
                  ensure smooth and seamless experiences for users.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-2"></div>
        </div>
      </div>
    </motion.div>
  );
}

export default Experience;
